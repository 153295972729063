<template>
  <div>
    <div class="overflow-hidden">
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation background cover-text ps-0"  style="margin-top: 50px">
          <h1>智能算法</h1>
          <p>在预设交易时间内，算法对价格趋势进行预测，完成自动化交易执行，获取交易环节超额收益</p>
        </div>
      </div>
    </div>
    <div class="content p-auto" style="margin-top:50px">
      <!--section>
        <div class="container-lg px-4 py-5">
          <div class="row align-items-center g-5 py-5">
            <div class="col-lg-12">
              <h1 class="display-5 fw-bold lh-1 mb-3 te">非凸智能算法</h1>
              <p class="lead mt-5">
                在预设的交易时间内，算法对价格趋势进行预测，并自动化执行交易，从而获取交易环节的超额收益。
              </p>
            </div>
          </div>
        </div>
      </section-->
      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-10">
              <div class="col-md-12 ml-auto">
                <h2>策略逻辑</h2>
            </div>
            <div class="mb-1">
              <div class="mb-1">
                <img class="w-100" src="../../assets/img/structure.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      <!-- section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>策略逻辑</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">原始高频数据</h3>
                    <p class="text-dark text-left">
                      个股量价：实时更新的逐笔委托与逐笔成交的超高频数据<br />
                      关联股票量价：全行业指数/概念指数/龙头lead股票等实时行情推送数据
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-danger">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">智能特征工程</h3>
                    <p class="text-dark text-left">
                      智能：根据所选模型及交易场景对海量数据进行不同的特征处理<br />
                      特征工程：去量纲（标准化、归一化），缺失值处理（样条插值），降维（PCA、SVM）等
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-orange">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">人工智能生成信号</h3>
                    <p class="text-dark text-left">
                      多维度模型：在线性模型基础上加入深度循环神经网络、BP全连接网络以及极度提升决策树集成学习模型<br />
                      目的：使得输出信号系统更加稳健，泛化能力突出
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-cyan">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">从信号到交易</h3>
                    <p class="text-dark text-left">
                      接收：训练好模型后，将模型解析以备高效计算AI信号，根据实时行情输出信号值到交易系统接收数据库<br />
                      发送：接收到实时信号值后，实时推送到交易系统，根据不同的策略配置触发交易
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section-->
      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>策略优势</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/多模型策略.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">多模型策略</h3>
                    <p class="text-dark text-left">
                      根据高低价股开发不同策略，多模型运行通过模型之间的组合来提供信号
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/长周期预测.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">长周期预测</h3>
                    <p class="text-dark text-left">
                      实现全天候长周期预测，算法在5min-4h的交易任务中均表现出色
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/算法高迭代.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">算法高迭代</h3>
                    <p class="text-dark text-left">
                      Rust 全栈技术，10+轮迭代/天，30分钟/轮，包括新的模型信号和优化策略
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/绩效优表现.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">绩效优表现</h3>
                    <p class="text-dark text-left">
                      日均跑赢TWAP/VWAP 3-5BP，领先市场平均0-1BP水平
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
<section class="py-5">
      <div class="container-lg px-4">
        <div class="row mt-5 mb-5">
          <div class="col-md-12 ml-auto">
            <h2>算法合作</h2>
          </div>
          </div>
        <div class="mt-5 row d-none d-lg-flex">
          <div class="col-4">
            <div
              class="
                accordion accordion-flush
                h-100
                d-flex
                flex-column
                justify-content-center
              "
              id="accordion-product"
            >
              <div class="accordion-item border-0">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-quanshang"
                    aria-expanded="true"
                    aria-controls="collapse-quanshang"
                  >
                    券商端
                  </button>
                </h2>
                <div
                  id="collapse-quanshang"
                  class="accordion-collapse collapse show"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      总对总合作券商，上线算法总线直接使用，如中泰证券、国投证券、银河证券等
                    </div>
                 </div>   
                </div>
              </div>
              <div class="accordion-item border-0 mt-4">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-bendihua"
                    aria-expanded="false"
                    aria-controls="collapse-bendihua"
                  >
                    本地化
                  </button>
                </h2>
                <div
                  id="collapse-bendihua"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      客户端本地部署，支持大部分交易通道及多账户管理，部署便捷<br />
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item border-0 mt-4">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed fs-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-siyouhua"
                    aria-expanded="false"
                    aria-controls="collapse-siyouhua"
                  >
                    私有化
                  </button>
                </h2>
                <div
                  id="collapse-siyouhua"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordion-product"
                >
                  <div class="accordion-body">
                    <div class="mb-3">
                      将策略模块部署于托管服务器中，拆单在托管机内进行，保护数据隐私，保障安全合规
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <img
              v-if="collapse === 0"
              class="collapse-image"
              src="../../assets/img/products/quanshang.png"
              alt="非凸科技"
            />
            <img
              v-else-if="collapse === 1"
              class="collapse-image"
              src="../../assets/img/products/bendihua.png"
              alt="非凸科技"
            />
            <img
              v-else-if="collapse === 2"
              class="collapse-image"
              src="../../assets/img/products/siyouhua.png"
              alt="非凸科技"
            />
          </div>
        </div>
        <div class="row d-lg-none">
          <swiper
            ref="productSwiper"
            class="swiper overflow-visible"
            @slideChange="onProductSlideChange"
            :options="swiperOptionProduct"
          >
            <swiper-slide
              ><img
                class="collapse-image"
                src="../../assets/img/products/quanshang.png"
                alt="非凸科技"
              />
            </swiper-slide>
            <swiper-slide
              ><img
                class="collapse-image"
                src="../../assets/img/products/bendihua.png"
                alt="非凸科技"
              />
            </swiper-slide>
            <swiper-slide
              ><img
                class="collapse-image"
                src="../../assets/img/products/siyouhua.png"
                alt="非凸科技"
            /></swiper-slide>
            <template v-slot:pagination>
              <div class="product-swiper-pagination text-center"></div>
            </template>
          </swiper>
          <div class="product-swiper-text mt-3">
            <div v-if="collapse == 0">
              <div class="accordion-body">
                <div class="fs-4">券商端</div>
                <div class="mb-3">
                  总对总合作券商，上线算法总线直接使用，如中泰证券、国投证券、银河证券等
                </div>
              </div>
            </div>
            <div v-else-if="collapse == 1">
              <div class="accordion-body">
                <div class="fs-4">本地化</div>
                <div class="mb-3">
                  客户端本地部署，支持大部分交易通道及多账户管理，部署便捷
                </div>
              </div>
            </div>
            <div v-else-if="collapse == 2">
              <div class="accordion-body">
                <div class="fs-4">私有化</div>
                <div class="mb-3">
                  将客户端和服务端均部署于托管服务器中，全内网交互，极低延迟，保护隐私
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5">
              <div class="col-md-3 ml-auto">
                <h2>算法交易价值</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/降低交易成本.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">降低交易成本</h3>
                    <p class="text-dark text-left">
                      通过拆母单，拟合市场成交量分布，获取最佳交易执行路径，战胜市场均价
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/提升交易效率.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">提升交易效率</h3>
                    <p class="text-dark text-left">
                      计算机高速计算，提高交易执行速度，成交即时价格，打破传统手工交易较慢成交速度
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/减少人为干扰.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">减少人为干扰</h3>
                    <p class="text-dark text-left">
                      排除人工交易可能受到情绪、疲惫、反应速度等方面影响，以及防范人工操作风险
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/降低合规风险.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">降低合规风险</h3>
                    <p class="text-dark text-left">
                      内嵌风控阈值，智能判断潜在风险，降低犯错可能，严格符合监管要求
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/精准执行复杂策略.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">精准执行复杂策略</h3>
                    <p class="text-dark text-left">
                      基于海量历史和实时数据，发现人工较难以捕捉的交易机会，确保复杂策略得以执行
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5">
              <div class="col-md-3 ml-auto">
                <h2>客户场景</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/量化私募.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">私募基金</h3>
                    <p class="text-dark text-left">
                      一次交易上百只证券，同时减少频繁交易的滑价
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/价值投资基金.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">价值投资基金</h3>
                    <p class="text-dark text-left">
                      通过机器按算法下单换仓，规避人为干扰因素
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/公募基金.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">公募基金</h3>
                    <p class="text-dark text-left">
                      下单执行算法，提升大金额场景下的调仓效率
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/创投基金.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">创投基金</h3>
                    <p class="text-dark text-left">
                      减持解禁的限售股，实时追踪市场流动性情况
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="mx-3">
                    <img src="../../assets/img/products/智能算法/上市公司股东或个人高净值投资者.png"/>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">上市公司股东或个人高净值投资者</h3>
                    <p class="text-dark text-left">
                      增减持，降低交易成本，隐藏交易意图
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--section>
        <div class="py-5 bg-light">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>私募端算法部署</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">合作券商直接使用</h3>
                    <p class="text-dark text-left">
                      总对总合作券商，上线算法总线直接使用<br />
                      中泰证券、国投证券、银河证券等
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-cyan">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">QMT 增强交易</h3>
                    <p class="text-dark text-left">
                      通过 QMT 的增强交易功能，将客户端打包成 DLL 文件<br />
                      部署便捷，延迟较低（10~40ms）
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-danger">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">本地化部署</h3>
                    <p class="text-dark text-left">
                      客户端部署在交易计算机，服务端在非凸服务器<br />
                      通过互联网传递母单、子单信息
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="product_media">
                  <div class="product_icon mx-3 bg-primary">
                    <i class="bi bi-check2-square"></i>
                  </div>
                  <div class="media-body">
                    <h3 class="h4">私有化部署</h3>
                    <p class="text-dark text-left">
                      将客户端和服务端均部署于托管服务器中<br />
                      全内网交互，极低延迟
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section-->
      <!--section>
        <div class="py-5">
          <div class="container-lg px-4">
            <div class="row mt-5 mb-5">
              <div class="col-md-12 ml-auto">
                <h2>券商端算法部署</h2>
            </div>
            <div class="mb-1">
              <div class="mb-1">
                <img class="w-100" src="../../assets/img/券商端算法部署.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    </div>
    </div>
  </div>
</template>

<script>
  import "swiper/css/swiper.css";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "Algorithm",
  data() {
    return {
      notTouched: false,
      collapse: 0,
      swiperOptionProduct: {
        pagination: {
          el: ".product-swiper-pagination",
        },
        spaceBetween: 30,
      },
      swiperOptionHome: {
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
      },
    };
  },
    components: {
    Swiper,
    SwiperSlide,
  },
   computed: {
    productSwiper() {
      return this.$refs.productSwiper.$swiper;
    },
    isWeixin() {
      var ua = navigator.userAgent.toLowerCase();
      return (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == null
      );
    },
    isAndroid() {
      return navigator.userAgent.match(/android/i);
    },
  },
  methods: {
    onHover(event) {
      document.querySelectorAll(".location").forEach((ele) => {
        ele.classList.remove("active");
      });
      event.target.classList.add("active");
    },
    onProductSlideChange() {
      this.collapse = this.productSwiper.activeIndex;
    },
  },
  mounted() {
    var scope = this;
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        let video = document.getElementById("video");
        if (video != null && video != undefined) {
          document.getElementById("video").play();
        }
      },
      true
    );
    document.addEventListener(
      "touchstart",
      function () {
        let video = document.getElementById("video");
        if (video != null && video != undefined) {
          scope.notTouched = false;
          document.getElementById("video").play();
        }
      },
      true
    );

    document
      .getElementById("collapse-quanshang")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 0;
      });

    document
      .getElementById("collapse-bendihua")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 1;
      });

    document
      .getElementById("collapse-siyouhua")
      .addEventListener("show.bs.collapse", function () {
        scope.collapse = 2;
      });
  }
};
</script>


<style scoped>

.collapse-image {
  width: 100%;
  height: 100%;
}

.accordion-button:not(.collapsed) {
  box-shadow: unset;
  background-color: unset;
}
.accordion-button.collapsed {
  opacity: 0.65;
}
.accordion-button:after {
  display: none;
}
.jumbotron-wrapper.image {
  position: relative;
  top: 50px;
  background-image: url("~@/assets/img/products/智能算法.png");
}
.cover-text {
  color: unset;
  text-shadow: none;
}
</style>
